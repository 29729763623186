import React, { useEffect, useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { PublicPrivacy } from './pages/public-privacy/PublicPrivacy'

function App() {
  const [isFirebaseInit, setIsFirebaseInit] = useState(true)

  useEffect(() => {
    fetch('/__/firebase/init.json').then(async response => {
      initializeApp(await response.json());
      setIsFirebaseInit(false)
    });
  }, [])

  if (isFirebaseInit) {
    return <div>Loading</div>
  }

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>
              Initial community managments system page
            </p>
            <a
              className="App-link"
              href="https://reactjs.org"
              target="_blank"
              rel="noopener noreferrer"
            >
              Firebase SDK Version { getAuth().config.sdkClientVersion } with privacy
            </a>
          </header>
        }/>
        <Route path="privacy-policy" element={<PublicPrivacy />} />
      </Routes>
    </div>
  );
}

export default App;
